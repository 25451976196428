import React from 'react'
import './range-slider.css'

class RangeTime extends React.Component {
  constructor(props) {
    super(props)

    this._from = 'duration_from'
    this._to = 'duration_to'

    this._msCropped = 0

    const [_from, _to] = this.props.values

    this.state = {
      [this._from]: this.fromMillis(_from),
      [this._to]: this.fromMillis(_to),
    }
  }

  fromMillis = millis => {
    const minutes = Math.trunc(millis / 60000)
    const [seconds, msCropped] = String((millis % 60000) / 1000).split('.')

    if (msCropped) {
      this._msCropped = msCropped / '1'.padEnd(msCropped.length + 1, 0)
    }

    return seconds === 60
      ? minutes + 1 + ':' + '00'
      : minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }

  toMillis = (time, name) => {
    const [minutes, seconds] = time.split(':')
    const check =
      name === this._to ? Number(seconds) + this._msCropped : Number(seconds)
    const _seconds = check % 60

    return minutes * 60000 + _seconds * 1000
  }

  componentDidUpdate(prevProps, prevState) {
    const [prevFrom, prevTo] = prevProps.values
    const [_from, _to] = this.props.values

    if (prevFrom !== _from || prevTo !== _to) {
      this.setState({
        [this._from]: this.fromMillis(_from),
        [this._to]: this.fromMillis(_to),
      })
    }
  }

  inputOnChangeHandler = event => {
    let {
      target: {
        value,
        dataset: { name },
      },
    } = event
    value = value.replace(/[A-Za-zА-Яа-яЁё\.\,]/, '')
    const milliseconds = this.toMillis(value, name)
    const [minutes, seconds] = value.split(':')

    if (
      milliseconds > this.props.max ||
      value.indexOf(':') === -1 ||
      Number(seconds) >= 60
    ) {
      return null
    }

    const val = value.replace(/(\d+):^(\d+)$/g, `$1:$2`)
    const croppedTo = val.indexOf(':') + 3

    let values = this.props.values
    const valuesCurrIndex = Number(this._from !== name)
    values[valuesCurrIndex] = milliseconds

    this.setState({ [name]: val.substring(0, croppedTo) })
  }

  inputHandler = event => {
    const { which, keyCode } = event
    const ASCIICode = which || keyCode

    if (!ASCIICode || ASCIICode === 13) {
      const _fromMillis = this.toMillis(this.state[this._from], this._from)
      const _toMillis = this.toMillis(this.state[this._to], this._to)
      this.props.onChanged([_fromMillis, _toMillis])
    }
  }

  _renderInput(name) {
    return (
      <div className="rangeSliderInputBox">
        <input
          type="text"
          placeholder="mm:ss"
          value={this.state[name]}
          className="rangeSliderInput"
          onBlur={this.inputHandler}
          onKeyPress={this.inputHandler}
          onChange={this.inputOnChangeHandler}
          data-name={name}
        />
      </div>
    )
  }

  render() {
    return (
      <output className="rangeSliderOutput">
        {this._renderInput(this._from)}
        {this._renderInput(this._to)}
      </output>
    )
  }
}

RangeTime.defaultProps = {
  min: 0,
  max: 0,
  onChanged: values => values,
  values: [0, 0],
}

export default RangeTime
