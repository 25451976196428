import React from 'react'
import Component from 'components/component'
import { DI } from 'modules'
import { IS_LANDING } from 'config'

class LandingHead extends Component {
  state = {
    tag: {},
  }

  componentDidMount() {
    this.setState({
      tag: this.props.tag,
    })
    this.props.actions.common.landingPage(true)
  }

  componentWillUpdate(nextProps) {
    const nextTag = nextProps.tag
    const currTag = this.props.tag

    if (nextTag !== currTag) {
      this.setState({
        tag: nextTag,
      })

      this.props.actions.common.landingPage(true)
    }
  }

  render() {
    const { tag } = this.state
    const img = tag?.image?.original?.url

    if (tag === null) {
      return null
    }

    return (
      <section
        className={Component.classList(
          'tag-landing-header',
          IS_LANDING && 'tag-landing-header--special',
          !img && 'tag-landing-header--no-cover'
        )}
        style={{ marginBottom: 0 }}
      >
        {img && (
          <div className="tag-landing-header__cover">
            <img src={img} alt={`#${tag.slug}`} />
          </div>
        )}
        <div className="tag-landing-header__inner">
          <div className="tag-landing-header__title">{tag.name}</div>
          <div
            className="tag-landing-header__text"
            dangerouslySetInnerHTML={{ __html: tag.description }}
            style={{ width: '100%' }}
          />
        </div>
      </section>
    )
  }
}

export default DI(['common'])(LandingHead)
